.sponsors-bar{
    flex-wrap: wrap;
    background-color: #f3f4f5;
    height: auto;
    div{
        flex: 1;
        max-width: 170px;
        min-width: 120px;
        margin: 2rem;
        img{
            width: 150px;
            height: auto;
        }
    }
}