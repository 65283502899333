/* Insert goolge Font here */
@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');
/* 

--color-component
--color-text
--color-subtext */

:root {
  --font-family: 'Anek Malayalam', sans-serif;
  --color-bg : rgb(229, 246, 248);
  --color-footer: rgb(48, 24, 6);
}

.scrollup{
  position: fixed;
  background: gray;
  right: 1rem;
  bottom: -20%;
  display: inline-flex;
  padding: .3rem;
  border-radius: .25rem;
  z-index: ;
  opacity: .8;
  transition: .4s;
}

.scrollup-icon{
  font-size: 1.25rem;
  color: white;
}
.scrollup:hover{
  background: rgb(207 18 18);
  opacity: 1;
}
.show-scroll{
  bottom: 3rem;
}