
.app-champ{
    flex: 1;
    width: 100%;
    flex-direction: column;
}
.champion-header-title{
    font-size: 2.75rem;
    font-weight: 300;
    text-align: center;
    color: black;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: var(--font-family);
    @media screen and (max-width: 450px) {
        font-size: 2rem;
    }

}
.champ-filter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .champ-filter-item{
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: aliceblue;
        color: #000;
        font-weight: 400;
        font-family: var(--font-family);

        cursor:pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: rgb(118, 118, 118);
            color: #fff;
        }
        @media screen and (min-width: 2000px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
    }
    .item-active{
        background-color: rgb(218, 56, 56);
        color: #000;
    }
}

.champ-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .champ-card-item{
        width: 270px;
        flex-direction: column;

        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;

        cursor:pointer;
        transition: all 0.3s ease;

        &:hover{
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        }
        @media screen and (min-width: 2000px) {
            width: 470px;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }
        @media screen and (max-width: 300px) {
            width: 100%;
            margin: 1rem;
        }
    }
}

.champ-card-img{
    width: 100%;
    height: 230px;

    position: relative;

    img{
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }
    @media screen and (min-width: 2000px) {
        height: 350px;
    }
}
.champ-card-content{
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    h3{
        margin-top: 1rem;
        font-family: var(--font-family);
        
    }

    h4{
        margin-top: 1rem;
        line-height: 1.5;
        text-align: center;
        font-family: var(--font-family);
        font-size: 1.5rem;
        font-weight: 500;
         @media screen and (min-width: 2000px) {
             margin-top: 3rem;
         }
    }
    .bold-text{
        font-size: 1rem;
        font-weight: 600;
        color: #000;
        font-family: var(--font-family);
        @media screen and (min-width: 2000px){
            font-size: 2rem;
        }
        @media screen and (max-width: 450px){
            font-size: 0.9rem;
        }
    }
    .champ-card-tag{
        position: absolute;

        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: #fff;
        top: -25px;
    }
}

