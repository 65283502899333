// .gallery { 
//     display: grid;
//     grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
//     grid-gap: 1px;
//     align-items: start;
//     justify-items: center;
//     margin: auto;
//     width: 504px;
//     img{
//         max-width: 100%;
//     }



// }

.gallery{
    display:flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;

    div{
        height: 19rem;
        margin: 0 1rem 1rem 0;
    }
    img{
        width: auto;
        height: 100%; 
        @media screen and (max-width: 450px){
            width: 100%;
        }
    }
}

