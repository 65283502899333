/* .App{
  background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
} */


::placeholder{
  color: black;
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

// body{
//     /* ff 3.6+ */
//     background:-moz-radial-gradient(circle at 8% 11%, rgba(255, 255, 252, 1) 5%, rgba(138, 153, 152, 0.57) 33%, rgba(88, 92, 93, 1) 100%);

//     /* safari 5.1+,chrome 10+ */
//     background:-webkit-radial-gradient(circle at 8% 11%, rgba(255, 255, 252, 1) 5%, rgba(138, 153, 152, 0.57) 33%, rgba(88, 92, 93, 1) 100%);

//     /* opera 11.10+ */
//     background:-o-radial-gradient(circle at 8% 11%, rgba(255, 255, 252, 1) 5%, rgba(138, 153, 152, 0.57) 33%, rgba(88, 92, 93, 1) 100%);

//     /* ie 10+ */
//     background:-ms-radial-gradient(circle at 8% 11%, rgba(255, 255, 252, 1) 5%, rgba(138, 153, 152, 0.57) 33%, rgba(88, 92, 93, 1) 100%);

//     /* global 92%+ browsers support */
//     background:radial-gradient(circle at 8% 11%, rgba(255, 255, 252, 1) 5%, rgba(138, 153, 152, 0.57) 33%, rgba(88, 92, 93, 1) 100%);
//   }

a {
  color: unset;
  text-decoration: none;
}
.p-text{
  font-size: 0.8rem;
  text-align: left;
  color: gray;
  line-height: 1.5;
  font-family: var(--font-family);
  @media screen and (min-width: 2000px) {
      font-size: 1.75rem;
  }
}
button{
  
  background: rgb(207 18 18) !important;
}
.head-text{
  font-size: 2.75rem;
  font-weight: 600;
  text-align: center;
  color: black;
  text-transform: capitalize;
  font-family: var(--font-family);
  @media screen and (min-width: 2000px){
    font-size: 4rem;
  }
  @media screen and (max-width: 450px){
    font-size: 2rem;
  }
}
.subtitle-text{
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  color: black;
  text-transform: capitalize;
  font-family: var(--font-family);

  @media screen and (min-width: 2000px){
    font-size: 3.5rem;
  }
  @media screen and (max-width: 450px){
    font-size: 1rem;
  }
}
.subtitle-text-p{
  font-size: 1.2rem;
  font-weight: 200;
  padding: 1rem 10rem;
  text-align: center;
  color: black;
  font-family: var(--font-family);

  @media screen and (min-width: 2000px){
    font-size: 3.5rem;
  }
  @media screen and (max-width: 450px){
    font-size: 1rem;
    padding: 1.5rem;
  }
}
.section-padding{
  padding: 4rem 6rem;
  @media screen and (max-width: 700px){
    padding: 4rem;
  }
  @media screen and (max-width: 550px){
    padding: 4rem 2rem; 
  }

}
.scale-up-center{
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.app-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes scale-up-center{
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {

  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}