.instructionals{
    flex:  1;
    width: 100%;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}
.complete-text{
    margin: 3rem 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: rgb(38, 176, 0);
    text-transform: capitalize;
    font-family: var(--font-family);
    @media screen and (min-width: 2000px){
      font-size: 3rem;
    }
    @media screen and (max-width: 450px){
      font-size: 2rem;
    }
}
.instruc-social-cards{
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 2rem 2rem 2rem;

    .instruc-social-card{
    
        min-width: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;

        padding: 1rem;
        border-radius: 10px;
        cursor: pointer;
        background-color: #fef4f5;
    
        transition: all 0.3s ease-in-out;
        &:hover {
            box-shadow: 0 0 25px #fef4f5
        }
    }
}


.instruc-footer-form{
    
    flex-direction: column;

    margin: 4rem 2rem;

    div{
        width: 60%;
        margin: 0.75rem 0;
        border-radius: 10px;
        cursor: pointer;
        background-color: #fef4f5;

        transition: all 0.3s ease-in-out;

        input{
            width: 100%;
            padding: 0.95rem;
            border: none;
            border-radius: 7px;
            background-color: #fef4f5;

            font-family: var(--font-family);
            outline: none;

        }
        &:hover{
            box-shadow: 0 0 25px #fef4f5;
        }
    }
    button{
        padding: 1rem 2rem;
        border-radius: 10px;
        border: none;
        background: rgb(154, 25, 25);
        color: white;
        font-weight: 500;
        font-family: var(--font-family);
        outline: none;
        margin: 2rem 0 0;
        font-size: 1rem;
        
        transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 25px #fef4f5
        }
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 1rem 0;
    }
}
.copyright{
    
    width: 100%;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    p{
        font-family: var(--font-family);
        color: black;
        font-size: 0.8rem;
    }

}


