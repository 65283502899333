@import url('https://fonts.cdnfonts.com/css/anurati');
#home {
  position: relative;
  height: 50rem;
  @media screen and (max-width: 450px){
    height: 0%;
  }

  
}

.app-header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    
  }

  @media screen and (max-width: 450px) {
    padding: 0rem 1rem; 
  }
}


.app-header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  img {
    width: 100%;
    height: 55rem;
    object-fit: contain;
    z-index: 1;
    @media screen and (max-width: 450px) {
      height: 0%;
    }
  }

  .overlay-circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 90%;
  }

  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }
  @media screen and (max-width: 450px) {
    margin: 0%;
  }

  .app-header-info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15rem;
    z-index: 3;
    width: 100%;
    height: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    .badge-cmp {
      padding: 1rem 2rem;
      // background: rgb(143, 143, 143);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
      // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      white-space: nowrap;
    }
     @media screen and (max-width:1200px) {
       width: 100%;
       margin-right: 0rem;
     }
     @media screen and (max-width: 450px) {
       bottom: 9rem;
     }
   }
}



.stack {
  display: grid;
  grid-template-columns: 1fr;
  color: var(--color);
  font-size: 1.5;
  --stacks: 3;
}

.stack span {
  font-family: 'Anurati', sans-serif;
  font-weight: 400;
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 4.5rem;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease infinite 2s alternate-reverse;
  @media screen and (min-width: 2000px) {
    font-size: 8rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
  
  
}
.stack span:nth-child(1) { --index: 0;}
.stack span:nth-child(2) { --index: 1;}
.stack span:nth-child(3) { --index: 2;}
.stack span:nth-child(odd) { --glitch-translate: 8px; }
.stack span:nth-child(even) { --glitch-translate: -8px; }


@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  };
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%, 100% {  text-shadow: none; transform: none; }
}