.navbar-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;

    

}
.navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.navbar-links-logo{
    margin-right: 2rem;
}
.navbar-links-logo img{
    height: 150px;
    width: 150px;
    object-fit: contain;
}
.navbar-links-container{
    display: flex;
    flex-direction: row;
}
.navbar-register-signin{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.navbar-links-container p,
.navbar-register-signin p,
.navbar-mobile-menu__container p {
    color: rgb(0, 0, 0);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-register-signin button,
.navbar-mobile-menu__container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: rgb(207 18 18);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}
.navbar-mobile-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}
.navbar-mobile-menu svg{
    cursor: pointer;
}
.navbar-mobile-menu__container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    z-index: 10;
    text-align: end;
    background:radial-gradient(circle at 8% 11%, rgba(255, 255, 252, 1) 5%, rgba(138, 153, 152, 0.57) 33%, rgba(88, 92, 93, 1) 100%);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

}
.navbar-mobile-menu__container p{
    margin: 1rem 0;
}
.navbar-mobile-register-signin{
    display: none;
}

@media screen and (max-width: 1050px) {
    .navbar-links-container{
        display: none;
    }
    .navbar-mobile-menu{
        display: flex;
    }
}
@media screen and (max-width: 700px) {
    .navbar-wrapper{
        padding: 2rem 4rem;
    }
}
@media screen and (max-width: 550px) {
    .navbar-wrapper{
        padding: 2rem;
    }
    .navbar-register-signin{
        display: none;
    }
    .navbar-mobile-menu__container{
        top: 20px;
    }
    .navbar-mobile-register-signin{
        display:block;
    }
}