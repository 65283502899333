.about-section{
    display: flex;
    flex-direction: row;
            
    @media screen and(max-width: 950px) {
        flex-direction: column;
    }
}
.about-info{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
        font-family: var(--font-family);
        font-weight: 500;
        margin-bottom: 1rem;
    }
    p{
        font-family: var(--font-family);
        font-weight: 200;
    }
    @media screen and(min-width: 950px) {
        margin-right: 2rem;
    }
}
// .about-img{
//     flex: 1;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     margin-left: 2rem;
//     max-width: 650px;
//     min-height: 650px;
//     @media screen and(max-width: 950px) {
//         margin-left: 0;
//         margin-top: 1rem;
//     }
//     img{
//         object-fit: contain;
//         width: 100%;
//         height: 100%;
//     }
// }
.swiper{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 2rem;
    max-width: 650px;
    min-height: 650px;
        @media screen and(max-width: 950px) {
        margin-left: 0;
        margin-top: 1rem;
        width: 100%;
    }

}
.swiper-slide{
    img{  width: 100%;
        height: 100%;
        object-fit: contain;}

}